import { defineStore } from "pinia"
import { fetchApiJson } from "@/services/repowerAPIService.js"
import {
  usePortfolioStoreFactory,
  useAllocationPointStoreFactory,
  useAssetStoreFactory,
} from "@/stores/repower/meteringPointStoreFactories.js"

const MAX_QUERY_STRING_ASSETS = 20

/**
 * Group an array of objects by each entry's the portfolioId property. The
 * result is an array with portfolioId as index and array values forming subsets
 * of the original array.
 */
function groupByPortfolio(array) {
  const grouped = []
  for (const item of array) {
    if (!grouped[item.portfolioId]) {
      grouped[item.portfolioId] = []
    }
    grouped[item.portfolioId].push(item)
  }
  return grouped
}

export default defineStore("meteringPointsStore", {
  state: () => ({
    portfolios: [],
    allocationPoints: [],
    assets: [],
    loadingAssets: false,
  }),
  getters: {
    allocationPointsByPortfolio: (state) =>
      groupByPortfolio(state.allocationPoints),
    assetsByPortfolio: (state) => groupByPortfolio(state.assets),
    /**
     * Generate a query string for each portfolio that includes the portfolio
     * name and the names of the first MAX_QUERY_STRING_ASSETS assets. This
     * allows users to search portfolios by the contained assets as well.
     */
    queryStringsByPortfolio(state) {
      const queryStrings = {}
      for (const portfolio of state.portfolios) {
        const assets =
          state.assetsByPortfolio[portfolio.id]?.slice(
            0,
            MAX_QUERY_STRING_ASSETS,
          ) ?? []
        queryStrings[portfolio.id] =
          `${portfolio.name} ${assets.map((asset) => asset.name).join(" ")}`
      }
      return queryStrings
    },
  },
  actions: {
    loadPortfolios(onSuccess, onFail) {
      fetchApiJson("/v2/portfolios/")
        .then((data) => {
          this.portfolios = []
          for (const portfolio of data) {
            const portfolioStore = usePortfolioStoreFactory(portfolio.id)
            portfolioStore.setMeteringPoint(portfolio)
            this.portfolios.push(portfolioStore)
          }
          onSuccess?.()
        })
        .catch((error) => {
          onFail?.(error)
        })
    },
    loadAllocationPoints(onSuccess, onFail) {
      fetchApiJson("/v2/allocation-points/")
        .then((data) => {
          this.allocationPoints = []
          for (const allocationPoint of data) {
            const allocationPointStore = useAllocationPointStoreFactory(
              allocationPoint.id,
            )
            allocationPointStore.setMeteringPoint(allocationPoint)
            this.allocationPoints.push(allocationPointStore)
          }
          onSuccess?.()
        })
        .catch((error) => {
          onFail?.(error)
        })
    },
    loadAssets(onSuccess, onFail) {
      this.loadingAssets = true
      fetchApiJson(`/v2/assets/?details=false/`)
        .then((data) => {
          this.assets = []
          for (const asset of data) {
            const assetStore = useAssetStoreFactory(asset.id)
            assetStore.setMeteringPoint(asset)
            this.assets.push(assetStore)
          }
          onSuccess?.()
        })
        .catch((error) => {
          onFail?.(error)
        })
        .finally(() => {
          this.loadingAssets = false
        })
    },
    findPortfolio(id) {
      return this.portfolios.find((p) => p.id === id)
    },
  },
})
